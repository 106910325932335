export const projects = [
  {
    id: "raytracer",
    title: "OpenGL RayTracer",
    subtitle: "C++, OpenGL",
    description:
      "Created a simple raytracing program using only the OpenGL library and C++. The program allows rendering a 3D scene with raytraced lighting, shadows, and reflections from any camera angle.",
    gif: "/gifs/rayTracer.gif",
    image: "/gifs/rayTracer.gif",
    link: "https://github.com/MCKoleman/RayTracer",
    github: "https://github.com/MCKoleman/RayTracer",
    body: [
      "",
    ],
    code: [
      "",
    ],
  },
  {
    id: "openmodeler",
    title: "OpenGL 3D Modeler",
    subtitle: "C++, OpenGL",
    description:
      "Created a 3D modeling program using only the OpenGL library and C++. The program allows rendering of any .obj file and allows the user to make changes to the model's transformations and to the individual triangles and vertices of the mesh.",
    gif: "/gifs/openModeler.gif",
    image: "/gifs/openModeler.gif",
    link: "https://github.com/MCKoleman/OpenModeler",
    github: "https://github.com/MCKoleman/OpenModeler",
  },
  {
    id: "studiokoleman",
    title: "Studio Koleman Website",
    subtitle: "JavaScript, MERN Stack",
    description:
      "Created the website for my company, Studio Koleman. I also made a built-in WebGL player to play games made by the company directly on the website.",
    gif: "/gifs/studioKoleman.gif",
    image: "/gifs/studioKoleman.gif",
    link: "https://studiokoleman.com/",
    github: "https://gitfront.io/r/MCKoleman/tJtZ1H48UXYj/StudioKolemanSite/",
  },
  {
    id: "koleman",
    title: "Personal Website",
    subtitle: "JavaScript, MERN Stack",
    description:
      "Created the website you are currently using! The website was made using NodeJS and React with TailwindCSS. I also hosted the website using the Firebase CLI and CloudFlare.",
    gif: "/gifs/kolemanSite.gif",
    image: "/gifs/kolemanSite.gif",
    link: "https://koleman.dev/",
    github: "https://github.com/MCKoleman/KolemanSite",
  },
  {
    id: "gwc",
    title: "Girls Who Code UF Website",
    subtitle: "JavaScript, MERN Stack",
    description:
      "Created the website for the UF branch of Girls Who Code. The website has an admin dashboard that allows admins to manage database operations or to make changes to the live website directly without touching any code.",
    gif: "/gifs/gwc.gif",
    image: "/gifs/gwc.gif",
    link: "http://girlswhocodegainesville.com/",
    github: "https://gitfront.io/r/MCKoleman/L5kDezLaZewM/GWC/",
  },
  {
    id: "lifeimpact",
    title: "Life Impact Conference Website",
    subtitle: "Wix",
    description:
      "Completely revamped and redesigned the website for an annual Christian conference. Created both a desktop and mobile view using Wix.",
    gif: "/gifs/lifeImpact.gif",
    image: "/gifs/lifeImpact.gif",
    link: "https://www.lifeimpacteast.org/",
    github: "",
  },
];

export const games = [
  {
    id: "pantrypanic",
    title: "Pantry Panic",
    subtitle: "Lead Engineer",
    description:
      "[WIP] Currently leading development of a 2D fantasy action roguelite cooking game. Created a procedural generation system with tools for game designers to build levels.",
    image: "./images/PantryPanicCover.png",
    gif: "./gifs/pantryPanic.gif",
    link: "https://mckoleman.itch.io/pantry-panic",
    github: "https://gitfront.io/r/MCKoleman/TsWntg2oczjo/PantryPanic/",
    tools: [
      "C#",
      "Unity"
    ],
  },
  {
    id: "bbb",
    title: "Bars, Barns, & Bartering",
    subtitle: "Lead Programmer",
    description:
      "Created a 2D trading simulation game in 48 hours with a team of 9 people. Worked as project manager, lead programmer, and UI programmer.",
    image: "/images/BBBCover.png",
    gif: "/gifs/bbb.gif",
    link: "https://studiokoleman.itch.io/bbb",
    github: "https://gitfront.io/r/MCKoleman/PWEz6XLt6P7Q/BBB/",
    tools: [
      "C#",
      "Unity"
    ],
  },
  {
    id: "hopshot",
    title: "Hopshot",
    subtitle: "Lead Programmer, Procedural Engineer",
    description:
      "Created a 2D procedurally generated puzzle platformer in 72 hours with a team of 4 people. Worked as project lead programmer, procedural engineer, and UI designer.",
    image: "/images/HopshotCover.png",
    gif: "/gifs/hopshot.gif",
    link: "https://mckoleman.itch.io/hopshot",
    github: "https://github.com/MCKoleman/Hopshot",
    tools: [
      "C#",
      "Unity"
    ],
  },
  {
    id: "universica",
    title: "Universica",
    subtitle: "Solo Developer",
    description:
      "Created a 2-player retro arcade space shooter in the style of Galaga. Worked as the only developer for 1.5 years. Published the game on Google Play in 2021.",
    image: "/images/UniversicaCover.png",
    gif: "/gifs/universica.gif",
    link: "https://mckoleman.itch.io/universica",
    github: "https://gitfront.io/r/MCKoleman/EpbFLGtNQGox/Universica/",
    tools: [
      "C#",
      "Unity"
    ],
  },
  {
    id: "buckethead4",
    title: "Buckethead 4: Magnets of Madness",
    subtitle: "Solo Developer",
    description:
      "Created a 2D puzzle platformer that allows the player to use the power of electromagnetism to their benefit. Worked as the only developer for 5 months.",
    image: "/images/Buckethead4Cover.png",
    gif: "/gifs/buckethead4.gif",
    link: "https://mckoleman.itch.io/buckethead4",
    github: "https://gitfront.io/r/MCKoleman/pp8M4LSRwL1k/Buckethead4/",
    tools: [
      "C#",
      "Unity"
    ],
  },
  {
    id: "skyward",
    title: "Skyward",
    subtitle: "Lead Programmer, Procedural Engineer",
    description:
      "Created a 3D procedurally generated dungeon crawler in 5 months with a team of 7 people. Worked as project manager, lead programmer, and procedural engineer.",
    image: "/images/SkywardCover.png",
    gif: "/gifs/skyward.gif",
    link: "https://mckoleman.itch.io/skyward",
    github: "https://github.com/MCKoleman/Skyward",
    tools: [
      "C#",
      "Unity"
    ],
  },
  {
    id: "foreverafterdeath",
    title: "Forever After Death",
    subtitle: "Lead Programmer, Procedural Engineer",
    description:
      "Created a 2D procedurally generated dungeon crawler in 48 hours with a team of 4 people. Winner of the \"Best UI/UX Design\" category for the MayDay 1.0 game jam.",
    image: "/images/ForeverAfterDeathCover.png",
    gif: "/gifs/foreverAfterDeath.gif",
    link: "https://mckoleman.itch.io/forever-after-death",
    github: "https://github.com/MCKoleman/ForeverAfterDeath",
    tools: [
      "C#",
      "Unity"
    ],
  },
  {
    id: "buckethead2",
    title: "Buckethead 2: The Saint of Paint",
    subtitle: "Lead Programmer",
    description:
      "Created a 2D action platformer in 48 hours with a team of 5 people. Winner of the \"Most Innovative Gameplay\" category for the Gator Game Jam 2021.",
    image: "/images/Buckethead2Cover.png",
    gif: "/gifs/buckethead2.gif",
    link: "https://mckoleman.itch.io/buckethead-2-the-saint-of-paint",
    github: "https://gitfront.io/r/MCKoleman/aisC2KHzeSJp/Buckethead2/",
    tools: [
      "C#",
      "Unity"
    ],
  },
];

export const skills = [
  "C#",
  "C/C++",
  "Unity",
  "Unreal Engine 4",
  "JavaScript",
  "MERN Stack",
  "Python",
  "Git / GitHub",
  "Project Management",
];

export const courses = [
  {
    id: "COP 4600",
    title: "Operating Systems",
    description: "Mutex, locks, OS principles and modification of a custom OS kernel in C.",
    learned: "Learned C programming, ",
  },
  {
    id: "CAP 4730",
    title: "Programming Computer Graphics",
    description: "C++ programming for graphics with OpenGL and HLSL/GLSL.",
    learned: "Learned gouraud and phong shading and how to program custom lighting, rendering, and raytracing programs.",
  },
  {
    id: "COP 3530",
    title: "Data Structures & Algorithms",
    description: "Custom implementation of various data structures and sorting/pathing algorithms in C++.",
    learned: "Learned how to create linked lists, stacks, queues, BSTs, ASTs, graphs, and implement BFS, DFS, Djikstra, and A* algorithms.",
  },
  {
    id: "CEN 3031",
    title: "Intro to Software Engineering",
    description: "Creation of a complete software product to meet customer specifications in JavaScript and MERN.",
    learned: "Learned GitHub, test driven development, and writing documentation.",
  },
  {
    id: "MAS 3114",
    title: "Computational Linear Algebra",
    description: "Vector and matrix math with MATLAB.",
    learned: "Learned eigenvalues, echelon forms, vector spaces, and vector transformations.",
  },
  {
    id: "COP 4020",
    title: "Programming Language Concepts",
    description: "OOP, regex, and creation of a custom programming language using Java.",
    learned: "Learned regex, parsing, lexing, and object oriented programming.",
  },
  {
    id: "DIG 4527C",
    title: "Game Design & Production",
    description: "2D game development in a team of 25 in Unity and C#.",
    learned: "Learned project management, leadership, and advanced game programming.",
  },
  {
    id: "DIG 3715",
    title: "Game Content Production 2",
    description: "3D game development in a team of 7 in Unity and C#.",
    learned: "Learned team management, 3D production, and procedural room generation.",
  },
  {
    id: "DIG 3713",
    title: "Game Content Production 1",
    description: "2D game development in Unity and C#.",
    learned: "Learned post-processing, level design, and the mechanics of fun.",
  },
  {
    id: "DIG 2632",
    title: "Creating Mobile Games",
    description: "2D game development in Unity and C#.",
    learned: "Learned principles of rapid prototyping, scripting, and rendering.",
  },
];

export const socials = [
  {
    title: "GitHub",
    image: "/images/githubLogo.png",
    link: "https://github.com/MCKoleman",
  },
  {
    title: "LinkedIn",
    image: "/images/linkedInLogo.png",
    link: "https://www.linkedin.com/in/manukolehmainen/"
  },
  {
    title: "Discord",
    image: "/images/discordLogo.png",
    link: "https://discordapp.com/users/MCKoleman#1952"
  },
  {
    title: "itch.io",
    image: "/images/itchioLogo.png",
    link: "https://mckoleman.itch.io"
  }
];